import React, { useContext, useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { QUERY_ALL_USER_VIDEOS } from "../../queries/page/mediacontent"
import { AppContext } from "../../contexts/AppContext"
import { MUTATION_DELETE_USER_MEDIA } from "../../queries/page/mediacontent"
import auth from "../../auth/auth"
import { NotificationManager } from "react-notifications"
import { NoDataMessage } from "../Styled"
import { getConfigValue, MEDIA_TYPE_VIDEO } from "../../constants/props"
import { TAB, LANDSCAPE, MOBILE, DEVICE_MOBILE } from "../../constants/breakpoints"
import SliderContainer from '../SliderContainer'
import UserVideoCard from "./UserVideoCard"

const SliderWrapper = props => {
  const [deleteMedia] = useMutation(MUTATION_DELETE_USER_MEDIA)

  const removeItem = data => {
    return deleteMedia({
      variables: {
        input: {
          where: {
            id: data.id,
          },
        },
      },
      context: {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      },
    })
      .then(response => {
        NotificationManager.success("Video removed...")
        // returnn true so that confirmation dialog closes
        props.setForceFetch(true)
        return true
      })
      .catch(() => {
        NotificationManager.error("Operation failed...")
        // returnn true so that confirmation dialog closes
        return true
      })
  }

  let { device } = useContext(AppContext)
  let slidesToShow = getConfigValue(device, "videos.slider.slides")

  const [state, setState] = useState({
    showLeftArrow: false,
    showRightArrow: props.data.length - slidesToShow <= 0 ? false : true,
  })

  let settings = {
    infinite: false,
    speed: 150,
    slidesToShow,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    responsive: [
      {
        breakpoint: TAB.minWidth,
        settings: {
          slidesToShow: LANDSCAPE.videos.slider.slides,
        },
      },
      {
        breakpoint: LANDSCAPE.minWidth,
        settings: {
          initialSlide: props.data.length > 0 ? 1 : 0,
          slidesToShow: MOBILE.videos.slider.slides,
          className: "sibling-visible", // would make overflow visible and hence would allow displaying parts of siblings
        },
      },
    ],
    afterChange: i => {
      setState({
        ...state,
        showLeftArrow: i > 0 ? true : false,
        showRightArrow: props.data.length - slidesToShow - i > 0 ? true : false,
      })
    },
  }

  if (props.data.length) {
    return props.data.length > 0 ? (
      <SliderContainer
        addPadding={
          device === DEVICE_MOBILE &&
          props.data.length > MOBILE.videos.slider.slides
            ? true
            : false
        }
        settings={settings}
        showLeftArrow={state.showLeftArrow}
        showRightArrow={state.showRightArrow}
        type={MEDIA_TYPE_VIDEO}
      >
        {props.data.map((item, i) => (
          <UserVideoCard
            key={i}
            {...item}
            data={item}
            setShowForm={props.setShowForm}
            setFormValues={props.setFormValues}
            removeItem={removeItem}
            hover={false}
          />
        ))}
      </SliderContainer>
    ) : null
  } else {
    return <NoDataMessage>No videos to show here...</NoDataMessage>
  }
}

const UserVideoList = props => {
  let { userState } = useContext(AppContext)

  // use fetchPolicy = 'network-only' to force fetch
  const { data, loading } = useQuery(QUERY_ALL_USER_VIDEOS, {
    variables: {
      userID: userState.id,
    },
    fetchPolicy: props.forceFetch ? "network-only" : undefined,
  })

  if (props.forceFetch) {
    // if force fetched in previous attempt reset flag
    props.setForceFetch(false)
  }

  return !loading ? (
    <React.Fragment>
      <SliderWrapper
        data={data.videos}
        setShowForm={props.setShowForm}
        setFormValues={props.setFormValues}
        setForceFetch={props.setForceFetch}
        type="uservideo"
      />
    </React.Fragment>
  ) : (
    <div>Loading...</div>
  )
}

export default UserVideoList
