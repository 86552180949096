import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { AppContext } from "../../contexts/AppContext"
import { useMutation, useQuery } from "@apollo/client"
import * as St from "../Styled"
import {
  CREATE_USER_MEDIACONTENT,
  MUTATION_UPDATE_USER_MEDIA,
} from "../../queries/page/mediacontent"

import { QUERY_ALL_PRESENTER_OPTIONS } from "../../queries/page/presenter"

import axios from "axios"
import auth from "../../auth/auth"
import { NotificationManager } from "react-notifications"

import {
  awsStationVideosPath,
  ROLE_STATIONADMIN,
  ROLE_ARTIST,
  awsArtistVideosPath,
} from "../../constants/props"

import useButtonLoader from "../../hooks/useButtonLoader"
import ProgressContent from "../ProgressBar"

const UserVideoForm = props => {
  // HOOKS FIRST
  let { device, userState } = useContext(AppContext)
  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: props.formValues,
  })
  const [createMedia] = useMutation(CREATE_USER_MEDIACONTENT)
  const [updateMedia] = useMutation(MUTATION_UPDATE_USER_MEDIA)
  const [coverImage, setCoverImage] = useState({ preview: "", raw: "" })
  const [mediaFile, setMediaFile] = useState({
    raw: undefined,
    name: undefined,
  })
  const [progressOfImage, setProgressOfImage] = useState({
    count: 0,
    title: "",
  })
  const [progressOfVideo, setProgressOfVideo] = useState({
    count: 0,
    title: "",
  })
  const [loaderElement, setLoader] = useButtonLoader(
    props.formValues.id ? "Update Video" : "Add Video",
    "Processing..."
  )

  let userRoleType =
    userState && userState.role && userState.role.type
      ? userState.role.type
      : undefined

  //
  const basePrefix = `${process.env.STRAPI_SERVER_URL}` // SERVER BASE

  // METHODS
  const handleMediaChange = e => {
    if (e.target.files.length) {
      setMediaFile({
        raw: e.target.files[0],
        name: e.target.files[0].name,
      })
    }
  }
  const handleImageChange = e => {
    if (e.target.files.length) {
      setCoverImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        name: e.target.files[0].name,
      })
    }
  }

  const uploadCoverImage = (coverImageFile, id) => {
    // cover image of audio file was updated during update operation

    if (!userRoleType) {
      return new Promise((resolve, reject) => {
        reject(new Error("No role user"))
      })
    }

    let coverImagePath =
      userRoleType === ROLE_STATIONADMIN
        ? awsStationVideosPath(userState.station.title)
        : userRoleType === ROLE_ARTIST
        ? awsArtistVideosPath(userState.firstname + " " + userState.lastname)
        : undefined

    const coverFormData = new FormData()
    coverFormData.append("files", coverImageFile)
    coverFormData.append("path", coverImagePath)
    coverFormData.append("ref", "mediacontent")
    coverFormData.append("refId", id)
    coverFormData.append("field", "image")
    return axios.post(`${basePrefix}/upload`, coverFormData, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setProgressOfImage({
          count: percentCompleted - 0.05 * percentCompleted,
          title: "Uploading " + coverImage.name,
        })
      },
    })
  }

  const uploadMediaFile = (mediaFile, id) => {
    if (!userRoleType) {
      return new Promise((resolve, reject) => {
        reject(new Error("No role user"))
      })
    }
    let mediaFilePath =
      userRoleType === ROLE_STATIONADMIN
        ? awsStationVideosPath(userState.station.title)
        : userRoleType === ROLE_ARTIST
        ? awsArtistVideosPath(userState.firstname + " " + userState.lastname)
        : undefined

    const mediaFormData = new FormData()
    mediaFormData.append("files", mediaFile)
    mediaFormData.append("path", mediaFilePath)
    mediaFormData.append("ref", "mediacontent")
    mediaFormData.append("refId", id)
    mediaFormData.append("field", "mediaUri")

    return axios.post(`${basePrefix}/upload`, mediaFormData, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setProgressOfVideo({
          count: percentCompleted - 0.05 * percentCompleted,
          title: "Uploading " + mediaFile.name,
        })
      },
    })
  }

  const updateMediaData = (data, where) => {
    return updateMedia({
      variables: {
        input: {
          data,
          where,
        },
      },
      context: {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      },
    })
  }

  const showNotification = (msg, success) => {
    success ? NotificationManager.success(msg) : NotificationManager.error(msg)
    setLoader(false)
    if (success) {
      // reset and hide form if success
      reset()
      props.setShowForm({
        show: false,
        edit: false,
      })
      // as data is updated or created force fetch data from server
      props.setForceFetch(true)
    }
  }

  const onSubmit = values => {
    if (values.id) {
      // Audio is updated by user
      setLoader(true)
      // Update basic data in collection first
      updateMediaData(
        {
          title: values.title,
          about: values.about,
          presenter:
            values.presenter && values.presenter.id
              ? values.presenter.id
              : undefined,
        },
        {
          id: values.id,
        }
      ).then(() => {
        if (coverImage.raw) {
          uploadCoverImage(coverImage.raw, values.id)
            .then(response => {
              setProgressOfImage({
                count: 100,
                title: "Image Successfully Uploaded",
              })
              if (mediaFile.raw) {
                uploadMediaFile(mediaFile.raw, values.id)
                  .then(mediaUploadResponse => {
                    setProgressOfVideo({
                      count: 100,
                      title: "Video Successfully Uploaded",
                    })
                    if (
                      mediaUploadResponse.data &&
                      mediaUploadResponse.data.length
                    ) {
                      // Set URI as it is currently used every where, need to change all code to use mediaUri
                      updateMediaData(
                        {
                          uri: mediaUploadResponse.data[0].url,
                        },
                        {
                          id: values.id,
                        }
                      )
                      showNotification("Video updated ...", true)
                    }
                  })
                  .catch(() => {
                    showNotification("Video upload failed...", false)
                  })
              } else {
                showNotification("Video updated ...", true)
              }
            })
            .catch(() => {
              showNotification("Cover image upload failed...", false)
            })
        } else {
          if (mediaFile.raw) {
            uploadMediaFile(mediaFile.raw, values.id)
              .then(mediaUploadResponse => {
                if (
                  mediaUploadResponse.data &&
                  mediaUploadResponse.data.length
                ) {
                  // Set URI as it is currently used every where, need to change all code to use mediaUri
                  updateMediaData(
                    {
                      uri: mediaUploadResponse.data[0].url,
                    },
                    {
                      id: values.id,
                    }
                  )
                  showNotification("Video updated ...", true)
                }
              })
              .catch(() => {
                showNotification("Video upload failed...", false)
              })
          } else {
            showNotification("Video updated ...", true)
          }
        }
      })
    } else {
      // User has created a new content
      setLoader(true)
      createMedia({
        variables: {
          input: {
            data: {
              title: values.title,
              about: values.about,
              premium: false,
              featured: false,
              confirmed: false,
              contenttype: "video",
              owner: userState.id,
              station: userState.stationID,
              artist: userState.artist ? userState.artist.id : null,
              uri: " ",
              presenter:
                values.presenter && values.presenter.id
                  ? values.presenter.id
                  : undefined,
            },
          },
        },
        context: {
          headers: {
            Authorization: "Bearer " + auth.getToken(),
          },
        },
      }).then(response => {
        if (
          response.data &&
          response.data.createMediacontent &&
          response.data.createMediacontent.mediacontent
        ) {
          const mediaID = response.data.createMediacontent.mediacontent.id
          uploadCoverImage(coverImage.raw, mediaID)
            .then(() => {
              setProgressOfImage({
                count: 100,
                title: "Image Successfully Uploaded",
              })
              uploadMediaFile(mediaFile.raw, mediaID)
                .then(mediaUploadResponse => {
                  setProgressOfVideo({
                    count: 100,
                    title: "Video Successfully Uploaded",
                  })
                  if (
                    mediaUploadResponse.data &&
                    mediaUploadResponse.data.length
                  ) {
                    // Set URI as it is currently used every where, need to change all code to use mediaUri
                    updateMediaData(
                      {
                        uri: mediaUploadResponse.data[0].url,
                      },
                      {
                        id: mediaID,
                      }
                    )
                  }
                  showNotification("Video saved successfully...", true)
                })
                .catch(() => {
                  showNotification("Video upload failed...", false)
                })
            })
            .catch(() => {
              showNotification("Cover image upload failed...", false)
            })
        }
      })
    }
  }

  const formValues = props.formValues ? props.formValues : {}

  let coverImageURL =
    formValues && formValues.image && formValues.image.url
      ? !formValues.image.url.startsWith("http")
        ? basePrefix + formValues.image.url
        : formValues.image.url
      : undefined

  let mediaURL = formValues.uri ? formValues.uri : undefined
  let videoFileName = formValues.mediaFileName
    ? formValues.mediaFileName
    : undefined

  return (
    <St.Form
      ismobile={device === "mobile"}
      onSubmit={handleSubmit(onSubmit)}
      enctype="multipart/form-data"
    >
      <div>
        {formValues.id && (
          <St.FormInput name="id" type="hidden" ref={register} />
        )}
        <St.Label>Title</St.Label>
        <St.FormInput
          name="title"
          aria-invalid={errors.title ? "true" : "false"}
          aria-describedby="titleError"
          ref={register({
            required: "Please add title for this video",
          })}
        />
        {errors.title && <St.ErrorText>{errors.title.message}</St.ErrorText>}
      </div>
      <div>
        <St.Label>Video (MP4)</St.Label>

        {mediaURL ? (
          <>
            <St.AudioFileLabel htmlFor="user-media">
              {mediaFile.name ? (
                <span>{mediaFile.name} (Selected)</span>
              ) : videoFileName ? (
                <span>{videoFileName}</span>
              ) : (
                <span>Click here to change mp4</span>
              )}
            </St.AudioFileLabel>
          </>
        ) : (
          <>
            <St.AudioFileLabel htmlFor="user-media">
              {mediaFile.name ? (
                <span>{mediaFile.name} (Selected)</span>
              ) : (
                <span>Click here to add mp4</span>
              )}
            </St.AudioFileLabel>
          </>
        )}
        <St.FormInputFile
          id="user-media"
          name="mediaFile"
          aria-invalid={errors.mediaFile ? "true" : "false"}
          aria-describedby="mediaFileError"
          accept="video/mp4"
          type="file"
          onChange={handleMediaChange}
          ref={
            !mediaURL
              ? register({
                  required: "Please select MP4",
                })
              : undefined
          }
        />

        {errors.mediaFile && (
          <St.ErrorText>{errors.mediaFile.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Cover Image (Recommended Size: 1000 X 380 pixels)</St.Label>
        <St.FileTypeLabel htmlFor="upload-cover">
          {coverImage.preview ? (
            <St.Image src={coverImage.preview} alt="dummy"></St.Image>
          ) : coverImageURL ? (
            <St.Image src={coverImageURL} alt="dummy"></St.Image>
          ) : (
            <> Click here to Upload Cover Image </>
          )}
        </St.FileTypeLabel>
        <St.FormInputFile
          id="upload-cover"
          name="coverImage"
          aria-invalid={errors.coverImage ? "true" : "false"}
          aria-describedby="coverImageError"
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleImageChange}
          ref={
            !coverImageURL
              ? register({
                  required: "Please select cover image",
                })
              : undefined
          }
        />
        {errors.coverImage && (
          <St.ErrorText>{errors.coverImage.message}</St.ErrorText>
        )}
      </div>
      {userRoleType === ROLE_STATIONADMIN && (
        <div>
          <St.Label>Presenter</St.Label>
          <St.FormSelect
            name="presenter.id"
            aria-invalid={errors.presenter ? "true" : "false"}
            aria-describedby="presenterError"
            ref={register()}
          >
            <option key={"empty"} value="">
              -- Select presenter --
            </option>
            {props.presenters &&
              props.presenters.length &&
              props.presenters.map((presenter, i) => (
                <option key={i} value={presenter.id}>
                  {presenter.title}
                </option>
              ))}
          </St.FormSelect>
        </div>
      )}
      <div>
        <St.Label>About</St.Label>
        <St.FormTextarea
          name="about"
          aria-invalid={errors.about ? "true" : "false"}
          aria-describedby="aboutError"
          ref={register({
            required: "Please fill something about this video",
          })}
        />
        {errors.about && <St.ErrorText>{errors.about.message}</St.ErrorText>}
      </div>
      <St.SubmitButton ref={loaderElement}>Submit</St.SubmitButton>
      {progressOfImage.count < 100 && (
        <ProgressContent
          progress={progressOfImage.count}
          title={progressOfImage.title}
        />
      )}
      {progressOfVideo.count < 100 && (
        <ProgressContent
          progress={progressOfVideo.count}
          title={progressOfVideo.title}
        />
      )}
    </St.Form>
  )
}

const UserVideoFormContainer = props => {
  let { userState } = useContext(AppContext)

  let { data, loading } = useQuery(QUERY_ALL_PRESENTER_OPTIONS, {
    variables: {
      userID: userState.id,
    },
    fetchPolicy: "network-only",
  })

  // do not render form until presenter loads
  if (loading) return null

  return (
    <UserVideoForm
      {...props}
      presenters={
        data && data.presenters && data.presenters.length ? data.presenters : []
      }
    />
  )
}

export default UserVideoFormContainer
