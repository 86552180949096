import React, { useState, useContext } from "react"
import UserVideoForm from "./form"
import UserVideoList from "./list"
import { AppContext } from "../../contexts/AppContext"
import * as St from "../Styled"
import { DEVICE_MEDIUM, DEVICE_LARGE } from "../../constants/breakpoints"

const ContentUserVideos = props => {
  // HOOKS FIRST
  let { device } = useContext(AppContext)
  const [forceFetch, setForceFetch] = useState(false)
  const [showForm, setShowForm] = useState({
    show: false,
    edit: false,
  })
  const [formValues, setFormValues] = useState({
    id: "",
    title: "",
    about: "",
    uri: "",
    preview: "",
  })

  return (
    <St.ContentWrapper>
      <St.ContentSection>
        <St.AdWrapper>
          <St.LeftAdSection />
        </St.AdWrapper>

        <St.CenterContentWrapper>
          <St.DataTopHeadingWrapper>
            <St.DataTopHeading>
              {showForm.show
                ? showForm.edit
                  ? `Update Video`
                  : `Add Video`
                : `Videos`}
            </St.DataTopHeading>
            <St.TopRightButton>
              {!showForm.show ? (
                <button
                  type="button"
                  onClick={() => {
                    setShowForm({
                      show: true,
                      edit: false,
                    })
                    setFormValues({})
                  }}
                >
                  Add Video
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() =>
                    setShowForm({
                      show: false,
                      edit: false,
                    })
                  }
                >
                  Back to List
                </button>
              )}
            </St.TopRightButton>
          </St.DataTopHeadingWrapper>
          <St.CenterContent>
            {showForm.show ? (
              <UserVideoForm
                {...{ device, setShowForm, formValues, setForceFetch }}
              />
            ) : (
              <UserVideoList
                {...{
                  setShowForm,
                  setFormValues,
                  setForceFetch,
                  forceFetch,
                }}
              />
            )}
          </St.CenterContent>
          <St.BottomAdSection />
        </St.CenterContentWrapper>
        <St.AdWrapper>
          <St.RightAdSection />
        </St.AdWrapper>
      </St.ContentSection>
    </St.ContentWrapper>
  )
}

export default ContentUserVideos
