import gql from "graphql-tag"

export const QUERY_PRESENTER_BY_ID = gql`
  query PresenterById($id: ID!) {
    person: presenter(id: $id) {
      id
      title
      image {
        id
        url
      }
      cover {
        id
        url
      }
      location
      contact
      about
      audios: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "audio", station_null: false }
      ) {
        id
        title
        image {
          id
          url
        }
        premium
        show {
          id
          title
        }
        station {
          id
          title
        }
      }
      videos: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "video", station_null: false }
      ) {
        id
        title
        contenttype
        uri
        image {
          id
          url
        }
        station {
          id
          title
        }
        updatedAt
        premium
      }
    }
  }
`

export const ADD_STATION_PRESENTER = gql`
    mutation createPresenter($input: createPresenterInput){
      createPresenter(input: $input) {
            presenter {
                id 
                title
                location
                image {
                  id
                  url
                }
                about
                contact
            }
        }
    }
`
export const QUERY_ALL_STATION_PRESENTER = gql`
  query AllStationPresenters($userID: ID!) {
    presenters(
      where: { owner: $userID }
    ) {
      id
      title
      image {
        id
        url
      }
      about
      contact
      location
      station {
        id
        title
      }
    }
  }
`

export const QUERY_ALL_PRESENTER_OPTIONS = gql`
  query AllStationPresenters($userID: ID!) {
    presenters(
      where: { owner: $userID }
    ) {
      id
      title
    }
  }
`
export const MUTATION_UPDATE_PRESENTER = gql`
    mutation updatePresenter($input: updatePresenterInput){
        updatePresenter(input: $input) {
        presenter{
            id
            title
            location
            image {
              id
              url
            }
            about
            contact
        }
        }
    }
`

export const MUTATION_DELETE_PRESENTER = gql`
    mutation deletePresenter($input: deletePresenterInput){
        deletePresenter(input: $input) {
            presenter {
                id
            }
        }
    }
`