import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`

const DeleteButton = styled.button`
  margin: 10px 0px;
  font-size: 1.5rem;
  padding: 6px 30px;
  background: #3b9092;
  color: #fff;
`
const CancelButton = styled.button`
  margin: 10px 0px;
  font-size: 1.5rem;
  padding: 6px 30px;
  background: #3b9092;
  color: #fff;
`

const ConfirmDialog = props => {
  return props.isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    props.cancelled()
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h3>Are you sure want to delete this {props.message}</h3>
                <ButtonWrapper>
                  <DeleteButton
                    type="button"
                    onClick={() => {
                      props.confirmed(props.data).then(response => {
                        if (response) {
                          // close dialog as operation is completed
                          props.cancelled()
                        }
                      })
                    }}
                  >
                    <span aria-hidden="true">Delete</span>
                  </DeleteButton>
                  <CancelButton
                    onClick={() => {
                      props.cancelled()
                    }}
                  >
                    Cancel
                  </CancelButton>
                </ButtonWrapper>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null
}

export default ConfirmDialog
