import React, { useContext } from "react"
import StyledImage from "../StyledImage"
import * as St from "../Styled"
import { MEDIA_TYPE_VIDEO } from "../../constants/props"
import iconEdit from "../../images/pages/edit.png"
import iconDelete from "../../images/pages/delete.png"
import { AppContext } from "../../contexts/AppContext"
import useModal from "../Modal/useModal"
import ConfirmDialog from "../Modal/ConfirmDialog"

const VideoCard = props => {
  const { updatePlayer } = useContext(AppContext)

  const { isShowing, toggle } = useModal()

  return (
    <St.VideoSliderWrapper>
      <ConfirmDialog
        isShowing={isShowing}
        confirmed={props.removeItem}
        cancelled={toggle}
        message={"item"}
        data={{ id: props.id }}
      />
      <St.VideoSliderImageWrapper>
        <StyledImage
          image={props.image.url}
          width={"100%"}
          height={"100%"}
          borderRadius={"0px"}
          imageAlt={props.title}
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()
            updatePlayer({
              type: MEDIA_TYPE_VIDEO,
              id: props.id,
              title: props.title,
              preview: props.image.url,
              uri: props.uri,
              station: props.station,
              artist: null, // Just to clear that station is the owner of playing video
            })
          }}
        />
      </St.VideoSliderImageWrapper>
      <St.VideoSliderInfoWrapper>
        <St.VideoSliderTitle style={{ textAlign: "center" }}>
          {props.title}
        </St.VideoSliderTitle>
        <St.OperationWrapper>
          <St.OperationIcon
            src={iconEdit}
            onClick={() => {
              props.setShowForm({
                show: true,
                edit: true,
              })
              props.setFormValues(props.data)
            }}
          />
          <St.OperationIcon
            src={iconDelete}
            onClick={() => {
              toggle()
            }}
          />
        </St.OperationWrapper>
      </St.VideoSliderInfoWrapper>
    </St.VideoSliderWrapper>
  )
}

export default VideoCard
